<template>
  <div>
    <b-overlay
      show
      spinner-variant="primary"
      spinner-type="grow"
      spinner-large
      rounded="sm"
      style="top: 25em;"
      v-if="overlayStatus"
    >
    </b-overlay>
    <div v-if="companies.length === 0 && overlayStatus === false">
      <Empty
        :no-button="false"
        icon="LoaderIcon"
        title="No hay datos para mostrar"
        content="Actualmente no hay proveedores, para ver el corte diario de cada proveedor, agrega uno primero."
        button-text="Crear un proveedor"
        :button-func="goToCreateCompany"
      />
    </div>
    <!-- Companies -->
    <b-tabs pills v-model="tabIndex">
      <b-tab v-for="company in companies" :key="company.companyId">
        <template #title>
          <feather-icon
            :icon="company.companyId ? 'UserIcon' : 'AlignCenterIcon'"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold"> {{ company.name }} </span>
        </template>
        <b-card>
          <!-- Header -->
          <b-card-header>
            <b-card-title> Corte Diario </b-card-title>
            <b-card-text> Elige los filtros a seleccionar </b-card-text>
          </b-card-header>
          <!-- Date Range -->
          <b-row align-h="center" class="mt-2">
            <b-col lg="4" md="4" sm="12">
              <b-form-group
                label="Fecha inicial"
              >
                <flat-pickr
                  class="form-control d-inline-block ml-50 mr-1"
                  :config="{ enableTime: true, dateFormat: 'd-m-Y H:i' }"
                  v-model="startDate"
                />
              </b-form-group>
            </b-col>
            <b-col lg="4" md="4" sm="12">
              <b-form-group
                label="Fecha final"
              >
                <flat-pickr
                  class="form-control d-inline-block ml-50 mr-1"
                  :config="{ enableTime: true, dateFormat: 'd-m-Y H:i' }"
                  v-model="endDate"
                />
              </b-form-group>
            </b-col>
            <b-col lg="4" md="4" sm="12">
                <b-form-group
                        label="Cliente"
                >
                    <v-select
                        v-model="sellerCompanyId"
                        label="name"
                        :options="sellerCompanies"
                        class="select-size-m"
                        :reduce="option => option.companyId"
                    />
                </b-form-group>
            </b-col>
          </b-row>
          <b-row align-h="center" class="mt-2">
              <b-col lg="4" md="4" sm="12">
                  <b-form-group
                          label="Usario que recibio"
                  >
                      <v-select
                              v-model="userId"
                              label="name"
                              :options="users"
                              class="select-size-m"
                              :reduce="option => option.userId"
                      />
                  </b-form-group>
              </b-col>
              <b-col lg="4" md="4" sm="12">
                  <b-form-group
                          label="Punto de venta"
                  >
                      <v-select
                              v-model="posId"
                              label="name"
                              :options="pointOfSales"
                              class="select-size-m"
                              :reduce="option => option.posId"
                      />
                  </b-form-group>
              </b-col>
              <b-col lg="4" md="4" sm="12">
                  <b-form-group
                          label="Bascula"
                  >
                      <v-select
                              v-model="scaleId"
                              label="name"
                              :options="scales"
                              class="select-size-m"
                              :reduce="option => option.scaleId"
                      />
                  </b-form-group>
              </b-col>
            </b-row>
          <b-row align-h="center">
            <b-button
              class="mt-1 mb-1"
              variant="primary"
              @click="findPurchaseOrdersByDailyCutFiltersApi(companies[tabIndex].companyId, tabIndex, (companies[tabIndex].companyId ? false : true))"
              :disabled="startDate === '' || endDate === ''"
            >
              <feather-icon
                icon="SearchIcon"
                class="mr-50"
              />
              Buscar corte diario
            </b-button>
          </b-row>
          <section>
            <hr>
            <b-overlay
              :show="tableOverlays[tabIndex]"
              rounded="sm"
            >
              <!-- Statistics -->
              <div class="mt-3 mb-3">
                <b-row>
                  <b-col lg="12" class="mb-1">
                    <h4 class="text-center mb-2">Resumen</h4>
                    <b-row align-h="center">
                      <b-col
                        lg="4"
                        md="3"
                        sm="12"
                        class="mb-2 d-flex justify-content-center"
                      >
                        <b-media no-body>
                          <b-media-aside
                            class="mr-2"
                          >
                            <b-avatar
                              size="48"
                              variant="light-info"
                            >
                              <feather-icon
                                size="24"
                                icon="BoxIcon"
                              />
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0" v-if="dailyCuts[tabIndex]">
                              {{ dailyCuts[tabIndex].totalQuantity }} kg
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                              Total de kilos
                            </b-card-text>
                          </b-media-body>
                        </b-media>
                      </b-col>
                      <b-col
                        lg="4"
                        md="3"
                        sm="12"
                        class="mb-2 d-flex justify-content-center"
                      >
                        <b-media no-body>
                          <b-media-aside
                            class="mr-2"
                          >
                            <b-avatar
                              size="48"
                              variant="light-success"
                            >
                              <feather-icon
                                size="24"
                                icon="DollarSignIcon"
                              />
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0" v-if="dailyCuts[tabIndex]">
                              ${{ dailyCuts[tabIndex].totalAmount }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                              Total que se debio invertir
                            </b-card-text>
                          </b-media-body>
                        </b-media>
                      </b-col>
                      <b-col
                        lg="4"
                        md="3"
                        sm="12"
                        class="mb-2 d-flex justify-content-center"
                      >
                        <b-media no-body>
                          <b-media-aside
                            class="mr-2"
                          >
                            <b-avatar
                              size="48"
                              variant="light-success"
                            >
                              <feather-icon
                                size="24"
                                icon="DollarSignIcon"
                              />
                            </b-avatar>
                          </b-media-aside>
                            <b-media-body class="my-auto">
                                <h4 class="font-weight-bolder mb-0" v-if="dailyCuts[tabIndex]">
                                    ${{ dailyCuts[tabIndex].amountPaid }}
                                </h4>
                                <b-card-text class="font-small-3 mb-0">
                                    Total que se invirtio
                                </b-card-text>
                            </b-media-body>
                        </b-media>
                      </b-col>
                        <b-col
                                lg="4"
                                md="3"
                                sm="12"
                                class="mb-2 d-flex justify-content-center"
                        >
                            <b-media no-body>
                                <b-media-aside
                                        class="mr-2"
                                >
                                    <b-avatar
                                            size="48"
                                            variant="light-warning"
                                    >
                                        <feather-icon
                                                size="24"
                                                icon="DollarSignIcon"
                                        />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0" v-if="dailyCuts[tabIndex]">
                                        ${{ dailyCuts[tabIndex].difference }}
                                    </h4>
                                    <b-card-text class="font-small-3 mb-0">
                                        Diferencia por redondeo
                                    </b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
              <!-- Table -->
              <div>
                <b-table
                  hover
                  responsive
                  :items="dailyCuts[tabIndex].dailyCut"
                  :fields="tableColumns"
                  primary-key="name"
                  :sort-by.sync="sortBy"
                  :sort-desc="true"
                  show-empty
                  empty-text="No hay registros"
                  class="position-relative"
                >

                  <template #cell(quanitty)="data">
                    {{ data.value }} kg
                  </template>

                  <template #cell(subtotal)="data">
                    ${{ data.value }}
                  </template>

                </b-table>
              </div>
            </b-overlay>
          </section>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>


<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.vertical-line {
  border-left: 1px solid #e6e3e8;
}
</style>


<script>
import {
  BCard, BOverlay, BRow, BCol, BFormGroup, BButton, BAvatar, BTab, BTabs,
  BCardHeader, BCardTitle, BCardText, BMediaAside, BMedia, BMediaBody, BTable } from 'bootstrap-vue';
import utils from '@/utils/utils.js';
import Empty from '../components/empty-page/Empty';

import flatPickr from 'vue-flatpickr-component';
import { Spanish } from "flatpickr/dist/l10n/es.js";
import vSelect from "vue-select";
flatpickr.localize(Spanish);

export default {
  components: {
      vSelect,
    BCard,
    BCardText,
    BCardTitle,
    BCardHeader,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    flatPickr,
    BButton,
    BAvatar,
    BMediaAside,
    BMedia,
    BMediaBody,
    BTab,
    BTabs,
    BTable,
    Empty
  },
  data() {
    return {
      // Data
      dailyCuts: [],
      companies: [],

      sellerCompanies: [],
      users: [],
      pointOfSales: [],
      scales: [],

      sellerCompanyId: undefined,
      userId: undefined,
      posId: undefined,
      scaleId: undefined,

      tableOverlays: [],

      // Table
      tableColumns: [
        { key: 'name', label: 'Material', sortable: true },
        { key: 'quantity', label: 'Kilos', sortable: true },
        { key: 'subtotal', label: 'Dinero', sortable: true },
      ],
      sortBy: 'totalAmount',

      // Filter
      startDate: this.$moment().startOf('day').format('DD-MM-YYYY HH:mm'),
      endDate: this.$moment().endOf('day').format('DD-MM-YYYY HH:mm'),

      // Props
      overlayStatus: true,
      tabIndex: 0,
      adjustment: 0,
      finishedCompanies: 0,
      user: JSON.parse(localStorage.getItem("user")),
    }
  },
  created() {
    this.findCompaniesSellerApi()
    this.findPosUsersByCompanyApi()
    this.findPosByCompanyIdApi()
    this.findScalesByCompanyIdApi()
    this.getCompanies();
    // this.getAdjustment();
    // console.log(this.moment_tz.tz.guess());
  },
  methods: {
    getCompanies() {

        this.companies.push({"name": this.user.companyName, companyId: this.user.companyId });
        this.dailyCuts = new Array(this.companies.length).fill([]);
        this.tableOverlays = new Array(this.companies.length).fill(true);
        this.companies = this.companies.filter(company => company.companyId === this.user.companyId);
        this.findPurchaseOrdersByDailyCutFiltersApi(this.user.companyId, 0, false);

        this.overlayStatus = false;
      // const userData = JSON.parse(localStorage.getItem('userData'));
      // this.$axios.get(this.$services + 'companies/get_companies').then(res => {
      //   this.$refreshToken(res.headers['x-access-token']);
      //   if (!res.error) {
      //
      //     // General + extras
      //     this.companies.push({"name": "General"});
      //     this.companies.push(...res.data.data);
      //     this.dailyCuts = new Array(this.companies.length).fill(new Array());
      //     this.tableOverlays = new Array(this.companies.length).fill(true);
      //
      //     // Quitar proveedores extra si no eres broker, y llamar su corte diario
      //     if (this.$can('read', 'Calculator')) {
      //       this.companies = this.companies.filter(company => company.companyId == userData.companyId);
      //       this.findPurchaseOrdersByDailyCutFiltersApi(userData.companyId, 0, false);
      //     } else {
      //       // Llamar daily cut para todos los proveedores
      //       this.findPurchaseOrdersByDailyCutFiltersApi(null, null, null);
      //     }
      //   }
      // }).catch(error => {
      //   console.log(error);
      //   this.$verifyToken(error.response.data.data);
      // }).finally(() => {
      //   this.overlayStatus = false;
      // });
    },
    findPurchaseOrdersByDailyCutFiltersApi(companyId, index, general) {
      if (companyId) {
        this.$set(this.tableOverlays, index, true);
        this.$axios.post(this.$services + 'manager/findPurchaseOrdersByDailyCutFiltersApi', {
            sellerCompanyId: this.sellerCompanyId,
            userId: this.userId,
            posId: this.posId,
            scaleId: this.scaleId,
            buyerCompanyId: companyId,
            startDate: this.$moment(this.startDate, 'DD-MM-YYYY HH:mm').utc().format(),
            endDate: this.$moment(this.endDate, 'DD-MM-YYYY HH:mm').utc().format(),
        }).then(res => {
          if (res.data) {
            // General
            // if (general) {
              // this.dailyCuts[0].totalAmount = res.data.totalAmount;
              // this.dailyCuts[0].totalQuantity = res.data.totalQuantity;

              // this.setGeneralInformation(res.data);
            // }
            const dailyCut = res.data
            this.dailyCuts.splice(index, 1, dailyCut);
            // this.finishedCompanies += 1;
            //
            // if (this.finishedCompanies == this.companies.length - 1) {
            //   this.$set(this.tableOverlays, 0, false);
            // }
          }
        }).finally(() => {
          this.$set(this.tableOverlays, index, false);
        });
      }
      // else {  // Pestaña general
      //
      //   this.finishedCompanies = 0;
      //   this.$set(this.tableOverlays, 0, true);
      //
      //   // Reiniciar contadores superiores
      //   this.dailyCuts[0].totalAmount = 0;
      //   this.dailyCuts[0].totalQuantity = 0;
      //
      //   // Llamar a todos los proveedores
      //   this.companies.slice(1).forEach((company, index) => {
      //     this.findPurchaseOrdersByDailyCutFiltersApi(company.companyId, index+1, true);
      //   });
      // }
    },
    setGeneralInformation(dailyCut) {
      dailyCut.forEach(element => {
        let item = this.dailyCuts[0].find(item => item.id == element.id);
        if (item) {
          item.realPrice += element.realPrice;
          item.realQuantity += element.realQuantity;
          item.alteredQuantity += element.alteredQuantity;
          item.alteredPrice += element.alteredPrice;
        } else {
          this.dailyCuts[0].push(JSON.parse(JSON.stringify(element)))
        }
      });
    },
    goToCreateCompany() {
      this.$router.push({ name: 'add-company' })
    },
    findCompaniesSellerApi() {
        this.$axios
        .post(this.$services + 'manager/findCompaniesSellerApi', {
            companyId: this.user.companyId
        })
        .then((res) => {
            this.sellerCompanies = res.data;
        })
    },
    findPosUsersByCompanyApi() {
        this.$axios
        .post(this.$services + 'manager/findPosUsersByCompanyApi', {
            companyId: this.user.companyId
        })
        .then((res) => {
            this.users = res.data;
        })
    },
    findPosByCompanyIdApi() {
        this.$axios
        .post(this.$services + 'manager/findPosByCompanyIdApi', {
            companyId: this.user.companyId
        })
        .then((res) => {
            this.pointOfSales = res.data;
        })
    },
    findScalesByCompanyIdApi() {
        this.$axios
        .post(this.$services + 'manager/findScalesByCompanyIdApi', {
            companyId: this.user.companyId
        })
        .then((res) => {
            this.scales = res.data;
        })
    },
  }
}
</script>
